<template>
  <div class="position-relative">
    <div class="row mb-5 talents-card" v-if="talents.length > 0">
      <div
        class="col-md-6 col-lg-4 text-center"
        v-for="(talent, i) in talents"
        :key="i"
        :talent="talent"
      >
        <TalentCard :talent="talent" />
      </div>
    </div>
  </div>
</template>

<script>
import TalentCard from "../talentCard/talentCard.vue";
export default {
  components: { TalentCard },
  props: ["talents"],
};
</script>

<style lang="scss" scoped></style>
