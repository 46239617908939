<template>
  <transition name="modal-fade">
    <div
      class="modal align-items-center"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="border-0 modal-header justify-content-center">
            <button type="button" class="btn-close" @click="close"></button>
          </div>

          <div class="pt-5 modal-body no-border">
            <div class="row">
              <div class="mb-4 text-center col-12 meetBox">
                <img
                  src="../../../../../assets/images/client/ic_meet-your-team.svg"
                  class="mb-4 img-fluid tw-mx-auto "
                />
                <h3 class="bold">
                  {{ $t("matchedTalents.meetYourTeam") }}
                </h3>

                <p class="px-0 px-md-5">
                  {{
                    $t(
                      "matchedTalents.matchingSystemSuccessfullyFilteredProfiles"
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "meetModal",
  props: ["isOpen"],
  watch: {
    isOpen: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
  },
  methods: {
    close() {
      document.body.classList.remove("modal-open");
      this.$emit("update", false);
    },
  },
};
</script>


<style>
.modal-open {
  overflow: hidden;
}
</style>



