<template>
  <div class="tw-flex matching-filter">
    <div
      class="tw-grid tw-grid-cols-1 tw-gap-4 tw-w-full tw-h-full tw-text-start"
    >
      <div
        class="tw-flex tw-flex-col"
        v-if="available_filters.includes('seniority_level')"
      >
        <label
          class="tw-text-primary tw-font-semibold tw-text-[18px] tw-mb-[10px]"
          >{{ $t("matchingFilter.seniorityLevel") }}</label
        >
        <v-select
          @input="applyFilter()"
          class="style-chooser"
          :placeholder="
            $t('matchingFilter.Select') +
            ' ' +
            $t('matchingFilter.seniorityLevel')
          "
          :components="{ OpenIndicator }"
          :options="seniorityLevel"
          :reduce="(name) => name.value"
          label="text"
          v-model="fiterData.seniority_level"
        >
        </v-select>
        <hr class="smallHr" />
      </div>

      <div
        class="tw-flex tw-flex-col"
        v-if="available_filters.includes('industry')"
      >
        <label
          class="tw-text-primary tw-font-semibold tw-text-[18px] tw-mb-[10px]"
          >{{ $t("matchingFilter.industry") }}</label
        >
        <v-select
          @input="applyFilter()"
          class="style-chooser"
          :components="{ OpenIndicator }"
          :placeholder="
            $t('matchingFilter.Select') + ' ' + $t('matchingFilter.industry')
          "
          :options="industries"
          v-model="fiterData.industry"
          :reduce="(name) => name.id"
          label="name"
        >
        </v-select>
        <hr class="smallHr" />
      </div>

      <div
        class="tw-flex tw-flex-col"
        v-if="isFullStack || isBackend || isFrontend"
      >
        <label
          class="tw-text-primary tw-font-semibold tw-text-[18px] tw-mb-[10px]"
          >{{ $t("matchingFilter.primaryLanguage") }}</label
        >
        <v-select
          @input="applyFilter()"
          class="style-chooser"
          :components="{ OpenIndicator }"
          :placeholder="
            $t('matchingFilter.Select') +
            ' ' +
            $t('matchingFilter.primaryLanguage')
          "
          :options="
            isFullStack || isBackend
              ? backEndLanguagesList
              : frontEndLanguagesList
          "
          label="name"
          :reduce="(name) => name.id"
          v-model="fiterData.primary_technical_language"
        >
        </v-select>
        <hr class="smallHr" />
      </div>

      <div class="tw-flex tw-flex-col" v-if="isFullStack">
        <label
          class="tw-text-primary tw-font-semibold tw-text-[18px] tw-mb-[10px]"
          >{{ $t("matchingFilter.secondaryLanguage") }}</label
        >
        <v-select
          @input="applyFilter()"
          class="style-chooser"
          :components="{ OpenIndicator }"
          :placeholder="
            $t('matchingFilter.Select') +
            ' ' +
            $t('matchingFilter.secondaryLanguage')
          "
          :options="frontEndLanguagesList"
          label="name"
          :reduce="(name) => name.id"
          v-model="fiterData.secondary_technical_language"
        >
        </v-select>
        <hr class="smallHr" />
      </div>

      <div
        class="tw-flex tw-flex-col"
        v-if="skillsOptions.length"
      >
        <label
          class="tw-text-primary tw-font-semibold tw-text-[18px] tw-mb-[10px]"
          >{{ $t("matchingFilter.skills") }}</label
        >
        <v-select
         :loading="isSkillsLoading"
          :disabled="isSkillsLoading"
          @input="applyFilter()"
          class="style-chooser"
          :components="{ OpenIndicator }"
          :placeholder="
            $t('matchingFilter.Select') + ' ' + $t('matchingFilter.skills')
          "
          :options="skillsOptions"
          label="name"
          multiple
          v-model="fiterData.skills"
          :reduce="(name) => name.id"
        >
        </v-select>
        <hr class="smallHr" />
      </div>

      <div
        class="tw-flex tw-flex-col"
        v-if="countriesList.length"
      >
        <label
          class="tw-text-primary tw-font-semibold tw-text-[18px] tw-mb-[10px]"
          >{{ $t("matchingFilter.country") }}</label
        >
        <v-select
          :loading="isCountriesLoading"
          :disabled="isCountriesLoading"
          @input="applyFilter()"
          class="style-chooser"
          :components="{ OpenIndicator }"
          :placeholder="
            $t('matchingFilter.Select') + ' ' + $t('matchingFilter.country')
          "
          :options="countriesList"
          v-model="fiterData.countries"
          label="name"
          multiple
          :reduce="(name) => name.id"
        >
          <template v-slot:option="option">
            {{ option.name }} ({{ option.talents_count }})
          </template>
        </v-select>
        <hr class="smallHr" />
      </div>

      <div
        class="tw-flex tw-flex-col"
        v-if="available_filters.includes('availability')"
      >
        <label
          class="tw-text-primary tw-font-semibold tw-text-[18px] tw-mb-[10px]"
          >{{ $t("matchingFilter.availability") }}</label
        >
        <div
          class="tw-flex tw-justify-between tw-flex-col tw-gap-6 md:tw-gap-0 md:tw-flex-row"
        >
          <Checkbox
            v-for="option in availabilityListWithAvailability"
            :key="option.value"
            :label="option.name"
            :inputValue="option"
            :value="option.value"
            v-model="availability"
            @input="applyFilter()"
            labelStyle="checkboxLabelStyle"
          />
        </div>
        <hr class="smallHr" />
      </div>

      <div
        class="tw-flex tw-flex-col"
        v-if="available_filters.includes('language')"
      >
        <label
          class="tw-text-primary tw-font-semibold tw-text-[18px] tw-mb-[10px]"
          >{{ $t("matchingFilter.language") }}</label
        >
        <v-select
          @input="applyFilter()"
          class="style-chooser"
          :components="{ OpenIndicator }"
          :placeholder="
            $t('matchingFilter.Select') + ' ' + $t('matchingFilter.language')
          "
          :options="LanguagesList"
          v-model="fiterData['language[name]']"
          :reduce="(name) => name.id"
          label="name"
        >
        </v-select>
        <div class="error-select-validation-message">
          <span
            style="color: #ff6f59"
            v-if="
              !$v.fiterData['language[name]'].$invalid &&
              !fiterData['language[name]']
            "
            >{{ $t("validation.required") }}</span
          >
        </div>
        <hr class="smallHr" />
      </div>

      <div
        class="tw-flex tw-flex-col"
        v-if="available_filters.includes('language')"
      >
        <label
          class="tw-text-primary tw-font-semibold tw-text-[18px] tw-mb-[10px]"
          >{{ $t("matchingFilter.languageLevel") }}</label
        >
        <v-select
          @input="applyFilter()"
          class="style-chooser"
          :components="{ OpenIndicator }"
          :placeholder="
            $t('matchingFilter.Select') +
            ' ' +
            $t('matchingFilter.languageLevel')
          "
          :options="levelsList"
          v-model="fiterData['language[level]']"
          @blur="$v.fiterData['language[name]'].$touch()"
          :reduce="(name) => name.id"
          label="name"
        >
        </v-select>
      </div>
      <div
        class="tw-flex tw-flex-col"
        v-if="available_filters.includes('qualified')"
      >
        <div :class="`shared-checkbox `">
          <label :class="`control control--checkbox  tw-font-medium `">
            {{ $t("matchingFilter.verifiedBySquadio") }}
            <input
              type="checkbox"
              v-model="fiterData.qualified"
              @change="applyFilter"
            />
            <div class="control__indicator"></div>
            <p class="tw-text-[#8f9fb3] tw-text-sm tw-font-normal">
              {{ $t("matchingFilter.verifiedBySquadioPassed") }}
            </p>
          </label>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { mapActions } from "vuex";

import Checkbox from "@/components/shared/formControls/checkbox/checkbox.vue";

import { requiredIf } from "vuelidate/lib/validators";
import "vue-select/dist/vue-select.css";
const svgString = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="11px" height="6px" viewBox="0 0 17 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ic_arrow-dropdown</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="#494D55" fill-rule="evenodd">
        <g id="01.24.profile-mobile-version" transform="translate(-325.000000, -586.000000)" fill="#121B21" fill-rule="nonzero">
            <g id="Group-18-Copy" transform="translate(17.000000, 536.000000)">
                <g id="Group-20" transform="translate(266.000000, 37.000000)">
                    <polygon id="ic_arrow-dropdown" transform="translate(50.125000, 18.000000) scale(1, -1) translate(-50.125000, -18.000000) " points="50.125 13 58.25 23 42 23"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`;

export default {
  components: {
    Checkbox,
  },
  props: {
    isOpenFilter: {
      type: Boolean,
    },
    isCountriesLoading: {
      type: Boolean,
    },
    isSkillsLoading: {
      type: Boolean,
    },
    role: {
      type: Number,
    },
    frontEndLanguagesList: {
      type: Array,
    },
    backEndLanguagesList: {
      type: Array,
    },
    seniorityLevel: {
      type: Array,
    },
    industries: {
      type: Array,
    },
    skillsOptions: {
      type: Array,
    },
    LanguagesList: {
      type: Array,
    },
    levelsList: {
      type: Array,
    },
    availabilityList: {
      type: Array,
    },
    countryList: {
      type: Array,
    },
  },
  validations: {
    fiterData: {
      ["language[name]"]: {
        required: requiredIf(function () {
          return !this.fiterData["language[level]"];
        }),
      },
    },
  },
  created() {
    this.applyFilter();
  },
  computed: {
    getMatchingFilter() {
      return this.$store.getters["client/getMatchingFilter"][this.role];
    },
    countriesList() {
      return this.countryList.map((item) => {
        return {
          ...item,
          id: item.id,
          name: this.$t(
            `countries.${[item.name.replaceAll(" ", "").replaceAll(".", "-")]}`
          ),
        };
      });
    },
    availabilityListWithAvailability() {
      if (this.meta) {
        return [
          {
            name: `${this.$t("matchingFilter.Full-time")} (${
              this.meta.full_time_count
            })`,
            id: "full_time",
          },
          {
            name: `${this.$t("matchingFilter.Part-time")}  (${
              this.meta.part_time_count
            })`,
            id: "part_time",
          },
          {
            name: `${this.$t("matchingFilter.Not-defined")} (${
              this.meta.undefined_count
            })`,
            id: "undefined",
          },
        ];
      } else {
        return this.availabilityList;
      }
    },
    isBackend() {
      return this.role == 1;
    },
    isFrontend() {
      return this.role == 2;
    },
    isFullStack() {
      return this.role == 3;
    },
  },
  watch: {
    isOpenFilter: {
      immediate: false,
      handler(newValue) {
        if (newValue) {
          this.applyFilter('justOpen');
        }
      },
    },
    countriesList: {
      immediate: false,
      handler(newValue, oldValue) {
        
        if (this.compareArrays(newValue, oldValue) ) return;
        this.fiterData.countries = this.fiterData.countries.filter((item) => {
          return this.countriesList.find((country) => country.id == item);
        });


      let availability = this.availability.map((item) => {
        return item.id ? item.id : item;
      });

      let qualified =
        typeof this.fiterData.qualified == "boolean"
          ? this.fiterData.qualified
            ? 1
            : 0
          : typeof this.fiterData.qualified == "number"
          ? this.fiterData.qualified
          : null;

      this.$emit("updateMeta", {
        meta: this.meta,
        fiterData: { ...this.fiterData, availability, qualified },
      });



      
        //this.applyFilter('countries');
      },
    },
    skillsOptions: {
      immediate: false,
      handler(newValue, oldValue) {

        if (this.compareArrays(newValue, oldValue)) return;
        this.fiterData.skills = this.fiterData.skills.filter((item) => {
          return this.skillsOptions.find((skill) => skill.id == item);
        });


      let availability = this.availability.map((item) => {
        return item.id ? item.id : item;
      });

      let qualified =
        typeof this.fiterData.qualified == "boolean"
          ? this.fiterData.qualified
            ? 1
            : 0
          : typeof this.fiterData.qualified == "number"
          ? this.fiterData.qualified
          : null;

      this.$emit("updateMeta", {
        meta: this.meta,
        fiterData: { ...this.fiterData, availability, qualified },
      });


          
      // this.applyFilter('skills');
      },
    },
    role: {
      immediate: true,
      handler() {
        this.reset();
      },
    },
  },
  data() {
    return {
      isLoading: false,
      availability: [],
      fiterData: {
        qualified: null,
        "language[name]": null,
        "language[level]": null,
        seniority_level: null,
        industry: null,
        skills: [],
        countries: [],
        primary_technical_language: null,
        secondary_technical_language: null,
      },
      available_filters:[
            "countries",
            "industry",
            "seniority_level",
            "availability",
            "language",
            "primary_technical_language",
            "secondary_technical_language",
            "skills",
            "qualified"
          ],
      meta: {
        total: 0,
      },
      OpenIndicator: {
        render: (createElement) =>
          createElement("div", {
            domProps: {
              innerHTML: svgString,
            },
          }),
      },
    };
  },
  methods: {
    ...mapActions({
      loadMatchingFilter: "client/loadMatchingFilter",
    }),
    compareArrays(arr1, arr2) {
      // First, check if the arrays have the same length
      if (arr1.length !== arr2.length) {
        return false;
      }

      // Helper function to compare two objects
      function isEqual(obj1, obj2) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        // Check if objects have the same number of properties
        if (keys1.length !== keys2.length) {
          return false;
        }

        // Check if all properties and their values are equal
        for (let key of keys1) {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }

        return true;
      }

      // Check if every object in arr1 has a corresponding object in arr2
      for (let obj1 of arr1) {
        const found = arr2.some((obj2) => isEqual(obj1, obj2));
        if (!found) {
          return false;
        }
      }

      // Check if every object in arr2 has a corresponding object in arr1
      for (let obj2 of arr2) {
        const found = arr1.some((obj1) => isEqual(obj2, obj1));
        if (!found) {
          return false;
        }
      }

      return true;
    },
    removeFilter(key) {
      const resetActions = {
        qualified: () => (this.fiterData.qualified = null),
        "language[name]": () => (this.fiterData["language[name]"] = null),
        "language[level]": () => (this.fiterData["language[level]"] = null),
        seniority_level: () => (this.fiterData.seniority_level = null),
        industry: () => (this.fiterData.industry = null),
        skills: () => (this.fiterData.skills = []),
        countries: () => (this.fiterData.countries = []),
        primary_technical_language: () =>
          (this.fiterData.primary_technical_language = null),
        secondary_technical_language: () =>
          (this.fiterData.secondary_technical_language = null),
        availability: () => (this.availability = []),
      };

      if (resetActions[key]) {
        resetActions[key]();
      }

      this.applyFilter("runFilter");
    },
    reset() {
      this.loadMatchingFilter(this.role);
      if (this.getMatchingFilter) {
        this.fiterData = {
          qualified: this.getMatchingFilter.qualified,
          "language[name]": this.getMatchingFilter["language[name]"],
          "language[level]": this.getMatchingFilter["language[level]"],
          seniority_level: this.getMatchingFilter.seniority_level,
          industry: this.getMatchingFilter.industry,
          skills: this.getMatchingFilter.skills,
          countries: this.getMatchingFilter.countries,
          primary_technical_language:
            this.getMatchingFilter.primary_technical_language,
          secondary_technical_language:
            this.getMatchingFilter.secondary_technical_language,
        };
        this.availability = this.availabilityListWithAvailability.filter(
          (item) => this.getMatchingFilter.availability.includes(item.id)
        );
      } else {
        this.fiterData = {
          qualified: null,
          "language[name]": null,
          "language[level]": null,
          seniority_level: null,
          industry: null,
          skills: [],
          countries: [],
          primary_technical_language: null,
          secondary_technical_language: null,
        };
        this.availability = [];
      }
      this.applyFilter("runFilter");
    },
    applyFilter(flag) {

      if (this.isLoading) return;
     


      
      if (
        !this.$v.fiterData["language[name]"].$invalid &&
        !this.fiterData["language[name]"]
      ) {
        return;
      }

      let availability = this.availability.map((item) => {
        return item.id ? item.id : item;
      });

      let qualified =
        typeof this.fiterData.qualified == "boolean"
          ? this.fiterData.qualified
            ? 1
            : 0
          : typeof this.fiterData.qualified == "number"
          ? this.fiterData.qualified
          : null;

      this.$emit("updateMeta", {
        meta: this.meta,
        fiterData: { ...this.fiterData, availability, qualified },
      });



  


      if (!this.isOpenFilter) {
        this.isLoading = false;
      
         if (flag === "runFilter") {
           this.$emit("runFilter");
         }
         return;
      }

      if (flag !== 'justOpen') {
          this.$emit("getSkills");
          this.$emit("getCountries");
      }

   
      

      this.isLoading = true;
      this.axios
        .get(`/api/client/projects/matching/${this.role}/filters`, {
          params: { ...this.fiterData, availability, qualified },
        })
        .then((result) => {
          this.available_filters = result.data.data.available_filters;
          this.meta = result.data.data.meta;

          this.$emit("updateMeta", {
            meta: this.meta,
            fiterData: { ...this.fiterData, availability, qualified },
          });

          

          if (flag === "runFilter") {
            this.$emit("runFilter");
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.matching-filter {
  .smallHr {
    margin: 0 auto;
    border-bottom: 1px solid rgba(226, 226, 226, 1);
    width: 100%;
    display: block;
    margin-top: 20px;
  }

  .style-chooser .vs__dropdown-toggle,
  .style-chooser .vs__dropdown-menu {
    min-height: 45px;
  }

  .vs__search::placeholder {
    font-size: 14px;
    color: rgba(62, 62, 62, 1);
  }

  .shared-checkbox .control {
    margin-bottom: 0;
    color: #504e4e;
    font-size: 16.2px;
    font-weight: 400;
  }
}

.rtl .matching-filter .shared-checkbox .control {
  padding-right: 30px;
}

.rtl .matching-filter .shared-checkbox .control__indicator {
  right: 0;
  top: -2px;
}

.rtl .vs__deselect {
  margin-right: 4px;
  margin-left: 0;
}
</style>
