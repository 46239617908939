<template>
  <div class="row d-flex align-items-center justify-content-between pagination-wrapper">
    <div class="col-lg-4 text-center text-lg-start pagination-wrapper-data">
      {{ $t('matchedTalents.showing') }} <span class="bold">{{paginator.from}}-{{paginator.to}}</span>  {{ $t('matchedTalents.from') }}
      <span class="bold">{{paginator.total}}</span>  {{ $t('matchedTalents.data') }}
    </div>
    <div class="col-lg-8 d-flex justify-content-lg-end justify-content-center align-items-center dir-ltr mt-4 mt-lg-0 ">
      <paginate
        class="d-flex"
        v-model="paginator.current_page"
        :page-count="paginator.last_page"
        :page-range="3"
        :margin-pages="2"
        :click-handler="clickCallback"
        :prev-text="'<'"
        :next-text="'>'"
        :container-class="'pagination'"
        :page-class="'page-item sub-pagination'"
        :initial-page="1"
        :active-class	="'active'"
        :next-link-class	="'pagination-arrow'"
        :prev-link-class	="'pagination-arrow'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import "./pagination.scss";

export default {
  components: { Paginate },
  props:["paginator"],
  methods: {
    clickCallback(pageNum) {
        this.$emit("handlePaginate", pageNum);
    },
  },
};
</script>

<style lang="scss"></style>
