<template>
  <div class="tw-flex tw-justify-between tw-w-full tw-items-center tw-my-5">
    <div class="tw-flex tw-flex-wrap-reverse tw-gap-y-2 ">

      <div
        v-for="(value, key) in formattedObject"
        :key="key"
        class="tw-flex tw-items-center tw-py-[5px] tw-px-3 tw-bg-[rgb(250,250,250)] tw-rounded-[20.5px] tw-border-[0.86px] tw-border-solid tw-border-[#e2e0e0] tw-cursor-default tw-me-2"
      >
        <span class="tw-text-[14px] tw-text-[#9f9d9d] tw-font-medium tw-pe-1"
          >{{ $t(`matchingFilter.${[key.toLowerCase()]}`) }} :</span
        >
        <span class="tw-text-[14px] tw-text-[#494d55] tw-font-medium">{{
          readableTag(key, value)
        }}</span>
        <span
          class="tw-text-[14px] tw-text-[#494d55] tw-font-medium tw-p-2 tw-cursor-pointer"
          @click="everseFormatKey(key)"
        >
          <img src="@/assets/images/ic_close.svg" height="10px" width="10px" />
        </span>
      </div>
    </div>
    <div class="tw-flex" v-if="Object.entries(formattedObject).length">
      <button
        class="tw-text-[#1a2433] tw-text-[14px] tw-font-semibold tw-underline tw-w-max "
        @click="$emit('clearFilter')"
      >
        {{ $t('matchingFilter.clearAll') }}
      </button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    filterData: {
      type: Object,
    },
    frontEndLanguagesList: {
      type: Array,
    },
    backEndLanguagesList: {
      type: Array,
    },
    seniorityLevel: {
      type: Array,
    },
    industries: {
      type: Array,
    },
    skillsOptions: {
      type: Array,
    },
    LanguagesList: {
      type: Array,
    },
    levelsList: {
      type: Array,
    },
    availabilityList: {
      type: Array,
    },
    countryList: {
      type: Array,
    },
    role : {
      type: Number,
    }
  },
  computed: {
     countriesList() {
      return this.countryList.map((item) => {
        return {
          id: item.id,
          name: this.$t(`countries.${[item.name.replaceAll(' ','').replaceAll('.','-')]}`),
        };
      });
    },
    filteredObject() {
      if (!this.filterData || typeof this.filterData !== 'object') {
        // Return an empty object if filterData is null, undefined, or not an object
        return {};
      }
      return Object.fromEntries(
        // eslint-disable-next-line no-unused-vars
        Object.entries(this.filterData).filter(([key, value]) => {
          return (
            value !== null &&
            value !== undefined &&
            value !== "" &&
            !(Array.isArray(value) && value.length === 0)
          );
        })
      );
    },
    formattedObject() {
      return Object.fromEntries(
        Object.entries(this.filteredObject).map(([key, value]) => {
          return [this.formatKey(key), value];
        })
      );
    },
  },
  methods: {
    readableTag(key, value) {
      if ( this.role == 3 && key === "Secondary Technical Language") {
        return this.frontEndLanguagesList.find((item) => item.id === value)
          .name;
      }
      else if ( this.role == 3 && key === "Primary Technical Language") {
        return this.backEndLanguagesList.find((item) => item.id === value)
          .name;
      }
      else if (this.role == 1 && key === "Primary Technical Language") {
        return this.backEndLanguagesList.find((item) => item.id === value)
          .name;
      }
      else if (this.role == 2 && key === "Primary Technical Language") {
        return this.frontEndLanguagesList.find((item) => item.id === value)
          .name;
      }
      else if (key === "Seniority Level") {
        return this.seniorityLevel.find((item) => item.value === value).text;
      } else if (key === "Industry") {
        return this.industries.find((item) => item.id === value).name;
      } else if (key === "Skills") {
        return this.skillsOptions.filter((item) => value.includes(item.id)).map((item) => item.name).join(", ");
      } else if (key === "Language Name") {
        return this.LanguagesList.find((item) => item.id === value).name;
      } else if (key === "Language Level") {
        return this.levelsList.find((item) => item.id === value).name;
      } else if (key === "Availability") {
        return this.availabilityList.filter((item) => value.includes(item.id)).map((item) => item.name).join(", ");
      }
      else if (key === "Countries") {
        return this.countriesList.filter((item) => value.includes(item.id)).map((item) => item.name).join(", ");
      }
      else if (key === "Qualified") {
        return value ? this.$t("matchingFilter.Yes") :  this.$t("matchingFilter.No");
      }
      else {
        return value;
      }
    },
    formatKey(key) {
      // Replace underscores with spaces, capitalize words, and remove square brackets for better readability
      return key
        .replace(/_/g, " ")
        .replace(/\[(.+?)\]/g, " $1")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    },
    everseFormatKey(key) {
      // Step 1: Lowercase the first letter of each word
      let result = key.replace(/\b[A-Z]/g, (char) => char.toLowerCase());

      // Step 2: Replace spaces with underscores
      result = result.replace(/ /g, "_");

      // Optional Step 3: Reinsert square brackets if you have specific rules
      // For example, converting "language name" back to "language[name]"
      result = result.replace(/language_name/g, "language[name]");
      result = result.replace(/language_level/g, "language[level]");
        if ( Object.entries(this.formattedObject).length == 1) {
            this.$emit('clearFilter')
        }
        else {
            this.$emit("removeFilter", result);            
        }
    },
  },
};
</script>

